/* Target the scrollbar */
::-webkit-scrollbar {
    width: 5px;
    /* Set the width of the scrollbar */
    height: 5px;
    /* Set the height for horizontal scrollbar */
}

/* Customize the track (background) */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Customize the handle (scroll thumb) */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    /* Round the corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}